@tailwind base;
html {
  scroll-behavior: smooth;
}
@tailwind components;
@tailwind utilities;
@import url("./css/satoshi.css");
@import url("./css/gambetta.css");

@layer utilities {
  .paused {
    animation-play-state: paused;
  }
}

@keyframes loop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

body {
  margin: 0;
  background: #0e0e0e;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Sa";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
