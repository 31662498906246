/**
 * @license
 *
 * Font Family: Gambetta
 * Designed by: Paul Troppmar
 * URL: https://www.fontshare.com/fonts/gambetta
 * © 2024 Indian Type Foundry
 *
 * Font Styles:
 * Gambetta Variable(Variable font)
 * Gambetta Variable Italic(Variable font)
 * Gambetta Light
 * Gambetta Light Italic
 * Gambetta Regular
 * Gambetta Italic
 * Gambetta Medium
 * Gambetta Medium Italic
 * Gambetta Semibold
 * Gambetta Semibold Italic
 * Gambetta Bold
 * Gambetta Bold Italic
 *
*/

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 300.0 'wght' 700.0;
*
* available axes:

* 'wght' (range from 300.0 to 700.0)

* 'wght' (range from 300.0 to 700.0)

*/

@font-face {
  font-family: "Gambetta-Variable";
  src: url("../fonts/gambetta/Gambetta-Variable.woff2") format("woff2"),
    url("../fonts/gambetta/Gambetta-Variable.woff") format("woff"),
    url("../fonts/gambetta/Gambetta-Variable.ttf") format("truetype");
  font-weight: 300 700;
  font-display: swap;
  font-style: normal;
}

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 300.0 'wght' 700.0;
*
* available axes:

* 'wght' (range from 300.0 to 700.0)

* 'wght' (range from 300.0 to 700.0)

*/

@font-face {
  font-family: "Gambetta-VariableItalic";
  src: url("../fonts/gambetta/Gambetta-VariableItalic.woff2") format("woff2"),
    url("../fonts/gambetta/Gambetta-VariableItalic.woff") format("woff"),
    url("../fonts/gambetta/Gambetta-VariableItalic.ttf") format("truetype");
  font-weight: 300 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Gambetta-Light";
  src: url("../fonts/gambetta/Gambetta-Light.woff2") format("woff2"),
    url("../fonts/gambetta/Gambetta-Light.woff") format("woff"),
    url("../fonts/gambetta/Gambetta-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Gambetta-LightItalic";
  src: url("../fonts/gambetta/Gambetta-LightItalic.woff2") format("woff2"),
    url("../fonts/gambetta/Gambetta-LightItalic.woff") format("woff"),
    url("../fonts/gambetta/Gambetta-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Gambetta-Regular";
  src: url("../fonts/gambetta/Gambetta-Regular.woff2") format("woff2"),
    url("../fonts/gambetta/Gambetta-Regular.woff") format("woff"),
    url("../fonts/gambetta/Gambetta-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Gambetta-Italic";
  src: url("../fonts/gambetta/Gambetta-Italic.woff2") format("woff2"),
    url("../fonts/gambetta/Gambetta-Italic.woff") format("woff"),
    url("../fonts/gambetta/Gambetta-Italic.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Gambetta-Medium";
  src: url("../fonts/gambetta/Gambetta-Medium.woff2") format("woff2"),
    url("../fonts/gambetta/Gambetta-Medium.woff") format("woff"),
    url("../fonts/gambetta/Gambetta-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Gambetta-MediumItalic";
  src: url("../fonts/gambetta/Gambetta-MediumItalic.woff2") format("woff2"),
    url("../fonts/gambetta/Gambetta-MediumItalic.woff") format("woff"),
    url("../fonts/gambetta/Gambetta-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Gambetta-Semibold";
  src: url("../fonts/gambetta/Gambetta-Semibold.woff2") format("woff2"),
    url("../fonts/gambetta/Gambetta-Semibold.woff") format("woff"),
    url("../fonts/gambetta/Gambetta-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Gambetta-SemiboldItalic";
  src: url("../fonts/gambetta/Gambetta-SemiboldItalic.woff2") format("woff2"),
    url("../fonts/gambetta/Gambetta-SemiboldItalic.woff") format("woff"),
    url("../fonts/gambetta/Gambetta-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Gambetta-Bold";
  src: url("../fonts/gambetta/Gambetta-Bold.woff2") format("woff2"),
    url("../fonts/gambetta/Gambetta-Bold.woff") format("woff"),
    url("../fonts/gambetta/Gambetta-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Gambetta-BoldItalic";
  src: url("../fonts/gambetta/Gambetta-BoldItalic.woff2") format("woff2"),
    url("../fonts/gambetta/Gambetta-BoldItalic.woff") format("woff"),
    url("../fonts/gambetta/Gambetta-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}
